import React from 'react';
import ContentLoader from 'react-content-loader'
import '../../Core/Styles/variables.scss';
import './loader.scss';

/**
 * Render Loader for Table view
 * @returns {JSX.Element}
 */
export const TableListingLoader = () => {
	return (
		<table>
			<tbody>
				{Array.from(Array(15), (e, i) => {
					return <tr key={i}>
						{Array.from(Array(5), (e, j) => {
							return <td key={j}>
								<ContentLoader
									speed={2}
									height={35}
									width={'97%'}
									backgroundColor="#ecebeb"
									foregroundColor="#d7d7d7"
								>
									<rect x="0" y="0" rx="0" ry="0" width="100%" height="35" />
								</ContentLoader>
							</td>
						})}
					</tr>
				})}
			</tbody>
		</table>
	)
}

/**
 * Render Loader for Card view
 * @returns {JSX.Element}
 */
export const CardListingLoader = () => {
	return Array.from(Array(6), (e, i) => {
		return <span key={i}><ContentLoader
			speed={2}
			height={352}
			width="100%"
			backgroundColor="#ecebeb"
			foregroundColor="#d7d7d7"
		>
			<rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />

		</ContentLoader></span>
	}
	)
}

/**
 * Render Loader for Card view
 * @returns {JSX.Element}
 */
export const ApplicationCardListingLoader = () => {
	return Array.from(Array(3), (e, i) => {
		return <span key={i}><ContentLoader
			speed={2}
			height={352}
			width="100%"
			backgroundColor="#ecebeb"
			foregroundColor="#d7d7d7"
		>
			<rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />

		</ContentLoader></span>
	}
	)
}

/**
 * Render Loader for Input view
 * @returns {JSX.Element}
 */
export const InputLoader = () => {
	return <table>
		{Array.from(Array(2), (e, i) => {
			return <tr key={i}>
				{Array.from(Array(2), (e, j) => {
					return <td key={j}>
						<ContentLoader
							speed={2}
							height={72}
							width={'98%'}
							backgroundColor="#ecebeb"
							foregroundColor="#d7d7d7"
						>
							<rect x="0" y="0" rx="4" ry="4" width="100%" height="72" />
						</ContentLoader>
					</td>
				})}
			</tr>
		})}
	</table>
}



export default ({ loading = false }) => {

	return (
		<>
			{loading ?
				(<div className="gooey" aria-label="Loading">
					<div className="dots">
						<span className="dot"></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>)

				: null
			}
		</>
	)

}